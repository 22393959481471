.MainBlock{
    padding: 10px;
    border-right: 1px solid black;
}   
.OptionsLink{
    width: 230px;
    height: 70px;
    background: #848484;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    transition: .3s;
    margin-bottom: 5px;
}
.OptionsLink:hover{
    opacity: .8;
}
.active div{
    background-color: #2D2D2D;
}