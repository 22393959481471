header{
    width: 100%;
    border-bottom: 1px solid black;
    align-items: center;
    justify-content: center;
    display: flex;
}
.HeaderBlock{
    flex-direction: row;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 97%;
}
.Logo{
    display: flex;
    flex-direction: row;

    align-items: center;
}
h3{
    margin-left: 20px;
    color: 'black';
    font-size: 19.90px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word
}
.Link{
    width: 145px;
    height: 45px;
    background: #DCAC00;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    text-decoration: none;
    color: black;
    transition: .3s;
}
a{
    text-decoration: none;
    color: black;
}
.Link:hover{
    opacity: .8;
}
.LinkWrapper{
    flex-direction: row;
    display: flex;
}