.Download {
    width: 145px;
    height: 45px;
    background: #0F6C00;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: black;
    transition: .3s;
    margin: 12px;
    text-align: center;
    color: #fff;
}

.Download:hover {
    opacity: .8;
}

.DataPage {
    width: 100%;
}

.notificationsButtons {
    background-color: #DCAC00;
    margin: 5px;
    height: 32px;
    width: 145px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: .3s;
}


.AddEvent {
    height: 45px;
    width: 145px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #D9D9D9;
    transition: .3s;
    margin: 12px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
}

.notificationsButtons:hover {
    opacity: .9;
}

.AddEvent:hover {
    opacity: .9;
}

.ImputWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 37px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    margin-top: 0px;
}

.ImputWrapper input {
    min-height: 36px;
    font-size: 16px;
    font-family: Inter;
    padding-left:12px;
    margin-top: 6px;
    font-weight: 400;
    width: 100%;
}
.ImputWrapper textarea {
    min-height: 36px;
    font-size: 16px;
    font-family: Inter;
    padding-left:12px;
    margin-top: 6px;
    font-weight: 400;
    width: 100%;
    resize: vertical;
}
.NewsPodiiButton{
    width: 330px;
    height: 40px;
    color: white;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    background-color: #2D2D2D;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}
tbody{
    flex: 1;
}
.UsersTbody tr th{
    min-height: 30px;
    height: 30px;
}
th{
    vertical-align:middle;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #000;
    display: flex;
    cursor: pointer;
    height: 120px;
    width: 120px;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    font-size: 64px;
}
.ImageDiv{
    align-items: center;
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    min-height: 141px;
}
.ImageWrapper{
    height: 120px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 1px solid black;
    flex-direction: column;
}

.ImageWrapper img{
    max-height: 110px;
    max-width: 170px;
}
.DeleteImg{
    margin-top: 75px;
    margin-left: 140px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    appearance: none;
    background-color: inherit;
    z-index: 11;
    width: 30px;
    min-height: 40px;
    background-image: url('../assets/garbege.png');
    background-size: 30px;
    background-repeat: no-repeat;
}
.DeleteImg img{
    height: 24px;
    z-index: -11;
}
.TimeWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 37px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    margin-top: 0px;
    width: 150px;
}
.TimeWrapper input{
    height: 40px;
    width: 150px;
}
.react-time-picker__inputGroup{
    align-items: center;
    display: flex;
    justify-content: center;
}
.react-time-picker{
    margin-top: 6px;
}
.TimePickerWrapper{
    display: flex;
    justify-content: space-between;
}
.arrowback{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    appearance: none;
    background-color: inherit;
    z-index: 11;
    width: 60px;
    height: 55px;
    background-image: url('../assets/arrow-back.png');
    background-size: 60px;
    background-repeat: no-repeat;
}
.arrowback:disabled{
    opacity: 0;
}
.arrowback:disabled:hover{
    cursor: default;
}

.pluss{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    appearance: none;
    background-color: inherit;
    z-index: 11;
    width: 60px;
    height: 40px;
    font-size: 54px;
    text-align: center;
}
.pollActiveButtons{
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    text-align: inherit;
    font: inherit;
    border-radius: 0;
    appearance: none; 
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
}
.disabledInput:disabled{
    background-color: #fff;
    border: 1px solid black;
    color: #000;
    margin-right: 18px;
}