table{
    width: 100%;
    font-family: Inter;
}
th{
    font-family: Inter;
    font-size: 18px;
}
.BlockInfo{
    background-color: rgb(92, 182, 241);
    padding: 6px;
    font-size: 14px;
}
.BlockInfo button{
    padding: 6px;
    margin: 6px;
    margin-top: 12px;
    font-family: Inter;
    border-radius: 40px;
}
.warn{
    padding: 6px;
    margin: 6px;
    margin-top: 12px;
    font-family: Inter;
    border-radius: 40px;
    background-color: #850000;
    color:#fff;
}

.CalTh{
    width: 13%;
}
.CalendarHeader{
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CalendarHeaderBlock{
    background-color: #E6E6E6;
    padding: 12px;
    border-radius: 32px;
    font-family: Inter;
    font-weight: 600;
}
.rotated{
    transform: rotate(180deg) translateY(-5px);
}
.Today{
    position: relative; 
    left: 0px;
    background-color: #ace2de86;
    padding: 12px;
    border-radius: 32px;
    font-family: Inter;
    font-weight: 600;
    margin-left: 16px;
}